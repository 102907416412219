import * as Yup from 'yup';
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const LoginForm = ({
  isAdmin,
  onSubmit
}) => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => onSubmit(values)
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/forgetpassword">
            Forgot password?
          </Link>
        </Stack>

        <div style={{marginTop: 25}}>
          {
            !isAdmin && (
              <div style={{display: "inline-block", width: "50%", paddingLeft: 15, paddingRight: 15}}>
                <LoadingButton
                  sx={{width: "100%"}}
                  size="large"
                  type="button"
                  variant="outlined"
                  onClick={() => history.push("/register")}
                >
                  Sign Up
                </LoadingButton>
              </div>
            )
          }
          <div style={{display: "inline-block", width: isAdmin ? "100%" : "50%", paddingLeft: 15, paddingRight: 15}}>
            <LoadingButton
              sx={{width: "100%"}}
              size="large"
              type="submit"
              variant="contained"
            >
              Login
            </LoadingButton>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};

LoginForm.defaultProps = {
  onSubmit: "default-alert",
};

export default LoginForm;