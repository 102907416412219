import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery, QueryClient } from "react-query";
import PropTypes from 'prop-types';
import { 
  Box,
  Card,
  Button,
  TableContainer,
  TextField,
} from '@mui/material';

import Scrollbar from 'components/Scrollbar';
import TableList from 'components/tableList';
import { getListWorkday } from "utils/common";
import { getWorkday, updateWorkday } from "services/workday.service";

import { WorkdayButton, WorkdayWrapper } from "./styled";

const Workday = () => {
  const queryClient = new QueryClient();
  const selectedOrg = useSelector((state) => state.userData?.orgId);
  const { isSuccess, data: resWorkday } = useQuery(["workdays", { organizationId: selectedOrg }], getWorkday);

  const listWorkday = getListWorkday();
  const [selectedWorkdays, setSelectedWorkdays] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      if (resWorkday?.data?.days?.length > 0) {
        setSelectedWorkdays(resWorkday.data.days);
      }
    }
  }, [isSuccess, resWorkday])

  const handleClickDay = (day) => {
    const isSameDay = selectedWorkdays.filter(x => x.name === day).length > 0 ? true : false;
    if (isSameDay) {
      setSelectedWorkdays(selectedWorkdays.filter(x => x.name !== day));
    } else {
      setSelectedWorkdays((_) => {
        return [
          ...selectedWorkdays,
          {
            name: day,
            startTime: "09:00",
            endTime: "18:00"
          }
        ]
      });
    }
  }

  const handleChange = (index, field, value) => {
    setSelectedWorkdays((_) => {
      const updatedValue = [...selectedWorkdays];
      updatedValue[index][field] = value;

      return updatedValue;
    })
  }

  const columnDays = useMemo(() => {
    return [
      {
        name: "Day",
        selector: row => row.name,
      },
      {
        name: "Start Time",
        selector: row => row.startTime,
        cell: row => (
          <div className="time-input">
            <TextField
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 minutes
              }}
              defaultValue={row.startTime}
              onChange={(e) => handleChange(row.key, "startTime", e.target.value)}
            />
          </div>
        )
      },
      {
        name: "End Time",
        selector: row => row.endTime,
        cell: row => (
          <div className="time-input">
            <TextField
              type="time"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 minutes
              }}
              defaultValue={row.endTime}
              onChange={(e) => handleChange(row.key, "endTime", e.target.value)}
            />
          </div>
        )
      }
    ]
  }, []);

  const sourceDays = useMemo(() => {
    return selectedWorkdays.map((day, index) => (
      {
        key: index,
        name: day.name,
        startTime: day.startTime,
        endTime: day.endTime,
      }
    ))
  }, [selectedWorkdays]);

  const submitWorkday = useMutation(values => {
    return updateWorkday({
      values: {
        days: values,
        organizationId: selectedOrg
      }
    });
  });

  const onSubmitWorkday = (values) => {
    submitWorkday.mutate(values, {
      onSuccess: (res) => {
        if (res)
          queryClient.setQueriesData(["workdays", { organizationId: selectedOrg }], res)
      },
      onSettled: () => {
        queryClient.invalidateQueries(["workdays", { organizationId: selectedOrg }]);
      }
    });
  }

  console.log(selectedWorkdays)

  return (
    <Card sx={{mt: 2}}>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Card>
            <WorkdayButton>
              {
                listWorkday.map((day, index) => (
                  <Button 
                    className={selectedWorkdays.filter(x => x.name === day).length > 0 ? "active" : "not-active"}
                    key={index}
                    sx={{m: 1}}
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleClickDay(day)}
                  >
                    {day}
                  </Button>
                ))
              }
            </WorkdayButton>
            
            <WorkdayWrapper>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <TableList
                    columns={columnDays}
                    data={sourceDays}
                    isFilter={true}
                  />
                </TableContainer>
              </Scrollbar>
            </WorkdayWrapper>

            <Button 
              sx={{m: 2}}
              size="large"
              type="button"
              variant="contained"
              onClick={() => onSubmitWorkday(selectedWorkdays)}
            >
              Submit
            </Button>
          </Card>
        </Box>
      </Box>
    </Card>
  );
}

Workday.propTypes = {
  selectedWorkdays: PropTypes.array,
  setSelectedWorkdays: PropTypes.func,
};

Workday.defaultProps = {
  selectedWorkdays: [],
  setSelectedWorkdays: () => {}
};

export default Workday;